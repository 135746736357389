
import Vue, { PropType } from 'vue';
import setup from '@/helpers/markdown';
import { TextAlign } from '@/domains/multicontent/text-align';
import { LessonContentItemText } from '@/domains/multicontent';

/**
 * Компонент, отображающий отформатированный текст, один из составляющх мультиконтента
 */
export default Vue.extend({
  name: 'LessonContentItemText',
  props: {
    content: {
      type: Object as PropType<LessonContentItemText>,
      required: true,
    },
  },
  data: () => ({
    textAlign: TextAlign,
  }),
  computed: {
    formattedText() {
      let html = '';

      try {
        const parser = setup();
        html = parser.render(this.content.value);
      } catch (e) {
        this.$di.snack.error('Не удалось распарсить текст из markdown в html');
      }

      return html;
    },

    align(): string {
      return this.content.options?.align ?? TextAlign.START;
    },
  },
});
