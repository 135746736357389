
import { defineComponent, PropType } from 'vue';
import { createNamespacedHelpers } from 'vuex';

import TTBackButton from '@/components/ui/TTBackButton.vue';
import UpButton from '@/components/ui/UpButton.vue';
import LessonContent from '@/components/multicontent/LessonContent/LessonContent.vue';
import StepsButtons from '@/domains/assignment/components/StepsButtons/StepsButtons.vue';

import { IButtonsState, IAssignmentMultiContent } from '@/domains/assignment';
import { IBreadcrumb, PlayerSessionStatus, UUID } from '@/domains/common';
import {
  multicontentCheckNewVersion,
  multicontentSessionComplete,
  multicontentSessionGet,
  multicontentSessionUpdate,
} from '@/services/api/multi-content';
import {
  IVideo,
  LessonContentItem,
  LessonContentItemBase,
  LessonContentItemType,
  LessonContentItemVideo,
} from '@/domains/multicontent';
import { ITrackSessionGetResponseData } from '@/services/api/tracks/types';

import AtomHasNewVersionBanner from '@/components/catalogue/AtomHasNewVersionBanner/AtomHasNewVersionBanner.vue';

import { IAtom } from '@/domains/atom/types';

import { atomGet } from '@/services/api/catalogue';
import { ICourse } from '@/domains/course';
import useMultiContentBody from '@/components/multicontent/composables/useMultiContentBody';
import { useFeatureFlags } from '@/plugins/feature-flags';

// Процент прогресса видео, после которого оно считается просмотренным
const MAX_VIDEO_PROGRESS: number = 90;

interface VideoProgress extends IVideo {
  isRewind?: boolean;
}

interface ITrackViewData {
  content: IAssignmentMultiContent;
  videoProgress: VideoProgress[];
  isLoading: boolean;
  isOpenDescription: boolean;
  isPageScrolledToEnd: boolean;
  isCompleteLessonSend: boolean;
  hasNewVersion: boolean;
  atom: IAtom;
}

const companyStore = createNamespacedHelpers('company');

export default defineComponent({
  name: 'AssignmentTrackStepMulticontent',

  components: {
    TTBackButton,
    UpButton,
    LessonContent,
    StepsButtons,
    AtomHasNewVersionBanner,
  },

  inject: ['Names'],

  props: {
    atomId: {
      type: String as PropType<UUID>,
      required: true,
    },

    catalogueAtomId: {
      type: String as PropType<UUID>,
      required: true,
    },

    track: {
      type: Object as PropType<IAtom | ICourse>,
      default: () => ({} as IAtom | ICourse),
    },

    buttonsState: {
      type: Object as PropType<IButtonsState>,
      default: () => ({ prev: { enabled: false } }),
    },

    isStepCompleted: {
      type: Boolean,
      default: false,
    },

    trackSession: {
      type: Object as PropType<ITrackSessionGetResponseData['trackSession']>,
      required: true,
    },
  },

  setup() {
    const { flags } = useFeatureFlags();
    const { parseContentBody } = useMultiContentBody({ features: flags });

    return { parseContentBody };
  },

  data(): ITrackViewData {
    return {
      content: {} as IAssignmentMultiContent,
      videoProgress: [],
      isLoading: false,
      isPageScrolledToEnd: false,
      isOpenDescription: false,
      isCompleteLessonSend: false,
      hasNewVersion: false,
      atom: {} as IAtom,
    };
  },

  computed: {
    ...companyStore.mapGetters(['companyId']),

    trackSessionId(): string {
      return this.$route.params.trackSessionId;
    },

    playerSessionId(): string | null {
      return this.$route.params.playerSessionId !== 'null' ? this.$route.params.playerSessionId : null;
    },

    stepId(): UUID {
      return this.$route.params.stepId;
    },

    contentBody(): LessonContentItem[] {
      return this.parseContentBody(this.content.body);
    },

    breadcrumbs(): IBreadcrumb[] {
      return [
        {
          text: this.$t('LearningTracks.title'),
          to: {
            name: this.Names.R_APP_LEARNING_TRACKS,
          },
          'data-test-label': 'my-program-link',
        },
        {
          text: this.trackTitle,
          to: {
            name: this.Names.R_APP_LEARNING_ASSIGNMENT_TRACK_VIEW,
            params: {
              playerSessionId: this.trackSessionId,
            },
          },
          'data-test-label': 'program-name-link',
        },
        {
          text: this.$t('LearningTracks.materials'),
          disabled: true,
          'data-test-label': 'program-materials',
        },
      ];
    },

    title(): IAssignmentMultiContent['title'] {
      return this.atom?.name || this.$t('Assignment.Course.defaultTrackName');
    },

    trackTitle(): string {
      return this.track.name ?? '';
    },

    trackDescription(): string {
      return this.atom.description ?? '';
    },

    providers(): IAssignmentMultiContent['providers'] {
      return this.content?.providers ?? [];
    },

    isVideosCompleted(): boolean {
      return this.videoProgress.every((item) => item.progress >= MAX_VIDEO_PROGRESS);
    },

    isSessionCompleted(): boolean {
      return this.content?.progress?.status === PlayerSessionStatus.COMPLETED;
    },
  },

  watch: {
    isStepCompleted: {
      immediate: true,
      handler(newValue: boolean) {
        this.isCompleteLessonSend = newValue;
      },
    },

    catalogueAtomId: {
      immediate: true,
      handler(v: UUID) {
        if (v) {
          this.fetchAtomDetails(v);
        }
      },
    },
  },

  async created() {
    await this.fetchSession();

    const { hasNewVersion = false } = await this.multicontentCheckNewVersion({
      playerSessionId: this.playerSessionId!,
    });

    this.hasNewVersion = hasNewVersion;
  },

  methods: {
    async fetchAtomDetails(atomId: UUID) {
      this.atom = await atomGet(atomId);
    },

    async fetchSession() {
      this.isLoading = true;
      try {
        const playerSessionId = Number(this.playerSessionId);
        const content = await multicontentSessionGet({ playerSessionId });

        if (!content.progress?.videos?.length) {
          const body = JSON.parse(content.body);
          const items = Array.isArray(body) ? body : [];

          this.videoProgress = items
            .filter((item: LessonContentItemBase) => item.type === LessonContentItemType.VIDEO)
            .map((item: LessonContentItemVideo) => ({
              videoId: item.options.videoId,
              progress: 1,
              duration: 0,
            }));

          await this.updateSession(this.videoProgress);
        } else {
          this.videoProgress = content.progress.videos ?? [];
        }

        this.content.body = this.prepareBody(content);
      } catch (e: any) {
        this.$di.notify.error({ content: this.$t('Assignment.Course.errors.fetchSession') });
      } finally {
        this.isLoading = false;
      }
    },

    prepareBody(content: IAssignmentMultiContent): string {
      const body = JSON.parse(content.body);
      const items = Array.isArray(body) ? body : [];

      return JSON.stringify(
        items.map((item) => {
          const result = { ...item };

          if (item.type === LessonContentItemType.FILES) {
            result.options = content.media.find((media) => media.id === item.value[0]);
          }

          return result;
        }),
      );
    },

    async updateSession(videoProgress: IVideo[]) {
      try {
        if (this.playerSessionId && videoProgress.length) {
          await multicontentSessionUpdate({
            playerSessionId: this?.playerSessionId,
            data: {
              playerSessionId: this.playerSessionId,
              videos: videoProgress.map(({ videoId, progress, duration }) => ({ videoId, progress, duration })),
            },
          });
        }
      } catch (e) {
        console.error(this.$t('Assignment.Course.errors.updateSession'));
      }
    },

    async completeSession() {
      if (this.isSessionCompleted || this.isCompleteLessonSend) {
        return;
      }

      try {
        if (this.playerSessionId) {
          await multicontentSessionComplete({
            playerSessionId: this.playerSessionId,
          });

          this.isCompleteLessonSend = true;
          this.$emit('step:completed', { stepId: this.stepId });
        }
      } catch (e) {
        console.error(this.$t('Assignment.Course.errors.completeSession'));
      }
    },

    async videoUpdate({ videoId, progress, duration }: { videoId: UUID; progress: number; duration: number }) {
      const videoIndex = this.videoProgress.findIndex((item) => item.videoId === videoId);

      // Если урок закончен, нам не нужно ничего трекать или перематывать
      if (this.isCompleteLessonSend) {
        return;
      }

      if (videoIndex >= 0) {
        // Если видео просмотрено до конца, нам тоже ничего не нужно делать
        if (this.videoProgress[videoIndex].progress > MAX_VIDEO_PROGRESS) {
          return;
        }

        // Если текущий видеопрогресс меньше, чем записанный на бэке, мы перемотаем видео
        // Перематываем если видео еще не просмотрено до конца и только первый раз при запуске
        if (this.videoProgress[videoIndex].progress > progress && !this.videoProgress[videoIndex].isRewind) {
          this.videoProgress[videoIndex].isRewind = true;
          // Найдем в контенте наше текущее видео, которое смотрит пользователь
          const contentItemIndex = this.contentBody.findIndex(
            (item) => (item as LessonContentItemVideo)?.options?.videoId === videoId,
          );

          if (contentItemIndex >= 0) {
            // Вычислим позицию, на которой был пользователь в прошлый раз в секундах
            const playback = duration * (this.videoProgress[videoIndex].progress / 100);

            // Перезапишем кусочек контента с видео на новую позицию сохраненного прогресса в видео,
            // чтобы перемотать пользователю видео на то место, где он остановился в прошлый раз
            const newItem = { ...(this.contentBody[contentItemIndex] as LessonContentItemVideo) };
            newItem.options.playback = playback;
            const tempContBody = JSON.parse(this.content.body);
            tempContBody.splice(contentItemIndex, 1, newItem);
            this.content.body = JSON.stringify(tempContBody);
          }
        }
        // Запишем на бэк прогресс текущего просмотра, пока пользователь смотрит видео
        if (this.videoProgress[videoIndex].progress < progress) {
          this.videoProgress.splice(videoIndex, 1, { ...this.videoProgress[videoIndex], progress });

          await this.updateSession(this.videoProgress);
        }
      }

      if (this.isVideosCompleted && this.isPageScrolledToEnd) {
        await this.completeSession();
      }
    },

    async onIntersect([entity]: IntersectionObserverEntry[]) {
      if (entity.isIntersecting) {
        this.isPageScrolledToEnd = true;

        if (this.isVideosCompleted) {
          await this.completeSession();
        }
      }
    },

    nextStep() {
      this.sendAnalytic(this.$t('AssignmentTrackStepMulticontent.nextStep'), 'go-next-step');
      this.$emit('step:next');
      this.$emit('next-step');
    },

    previousStep() {
      this.sendAnalytic(this.$t('AssignmentTrackStepMulticontent.prevStep'), 'go-prev-step');
      this.$emit('step:prev');
      this.$emit('prev-step');
    },

    sendAnalytic(eventValue: string, eventLabel: string) {
      const data = {
        companyId: this.companyId,
        playerSessionId: this.playerSessionId,
        stepId: this.stepId,
        trackSessionId: this.trackSessionId,
      };
      this.$di.tmt.sendEvent('send', 'click', eventValue, eventLabel, 'internallLink', data);
    },

    multicontentCheckNewVersion,
  },
});
