
import {
  computed, defineComponent, PropType, toRefs,
} from 'vue';
import { IButtonsState, ButtonsTypes } from '@/domains/assignment';

interface ButtonsProps {
  type: ButtonsTypes;
  disabled: boolean;
  isLoading: boolean;
  class: string;
  dataTestLabel: string;
  clickHandler: () => void;
  icon: string;
  tooltipText: string;
  showTooltip: boolean;
}

export default defineComponent({
  name: 'StepsButtons',

  props: {
    buttonsState: {
      type: Object as PropType<IButtonsState>,
      default: null,
    },
  },

  setup(props, { emit }) {
    const { buttonsState } = toRefs(props);

    const buttonsProps = computed(
      () => buttonsState.value ?? {
        prev: { enabled: false, isLoading: true },
        next: { enabled: false, isLoading: true },
        isShowNextStepLinearWarning: false,
      },
    );

    const buttons = computed((): ButtonsProps[] => (
      [{
        type: ButtonsTypes.prev,
        disabled: !buttonsState.value[ButtonsTypes.prev].enabled,
        isLoading: buttonsState.value[ButtonsTypes.prev].isLoading,
        class: 'px-0 mr-3',
        dataTestLabel: 'btn-previous',
        clickHandler: (() => {
          emit('step:prev');
          emit('prev-step');
        }),
        icon: '$angleLeft',
        tooltipText: buttonsState.value[ButtonsTypes.prev].tooltipText ?? '',
        showTooltip: buttonsState.value[ButtonsTypes.prev].showTooltip ?? false,
      },
      {
        type: ButtonsTypes.next,
        disabled: !buttonsState.value[ButtonsTypes.next].enabled,
        isLoading: buttonsState.value[ButtonsTypes.next].isLoading,
        class: 'px-0',
        dataTestLabel: 'btn-next',
        clickHandler: (() => {
          emit('step:next');
          emit('next-step');
        }),
        icon: '$angleRight',
        tooltipText: buttonsState.value[ButtonsTypes.next].tooltipText ?? '',
        showTooltip: buttonsState.value[ButtonsTypes.next].showTooltip ?? false,
      }]));

    return {
      buttonsProps,
      buttons,
      ButtonsTypes,
    };
  },
});
