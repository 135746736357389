import Markdown from 'markdown-it';
import Token from 'markdown-it/lib/token';

const defaultOptions = {
  quotes: '«»„“',
};

/**
 * Список правил для всех элементов markdown - назначим наши ui-kit классы для всех элементов
 * TODO: Нужно назначить наши классы для всех возможных html-свойств
 */
const setRules = (parser: Markdown) => {
  /**
   * Правило для параграфов
   */
  // eslint-disable-next-line no-param-reassign
  parser.renderer.rules.paragraph_open = () => '<p class="tt-text-body-1 content-item">';

  /**
   * Правило для заголовков: h1 - h3
   */
  // eslint-disable-next-line no-param-reassign
  parser.renderer.rules.heading_open = (tokens: Token[], currentIndexOfToken: number): string => {
    // Нигде не хранится данных о том, h1 это или h3, приходится брать level из списка токенов по индексу
    const headingLevel = Number(tokens[currentIndexOfToken].tag[1]);
    const headers = [
      'tt-text-title-1',
      'tt-text-title-2',
      'tt-text-subtitle',
    ];

    return `<h${headingLevel} class="${headers[headingLevel - 1]} mb-4 content-item">`;
  };
};

/**
 * Хелпер в себе создает экземпляр библиотеки для преобразования текста из markdown в html
 * Здесь же прописаны правила для тэгов, чтобы к ним добавлялись наши классы из ui-kit
 */
export default function setup(usersOptions = {}) {
  const parser = new Markdown({ ...defaultOptions, ...usersOptions });
  setRules(parser);

  return parser;
}
