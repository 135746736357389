
import { defineComponent, PropType, ref } from 'vue';
import { LessonContentItemLink, LessonContentItemType } from '@/domains/multicontent';

import { FeatureFlag } from '@/plugins/feature-flags/components';
import { trimURL } from '@/helpers/url';
import OEmbed from '@/components/ui/OEmbed/OEmbed.vue';

export default defineComponent({
  name: 'LessonContentItemLink',

  components: {
    FeatureFlag,
    OEmbed,
  },

  props: {
    content: {
      type: Object as PropType<LessonContentItemLink>,
      default: () => ({
        type: LessonContentItemType.LINK,
        value: '',
        options: {},
      }),
    },
  },

  setup() {
    return {
      isEmbed: ref<boolean>(false),
      trimURL,
    };
  },
});
