
import Vue, { PropType } from 'vue';
import { VideoJsPlayerOptions } from 'video.js';
import { LessonContentItemVideo, LessonContentItemVideoStatus } from '@/domains/multicontent';
import { cloneDeep } from '@/helpers/lodash';
import VideoPlayer from '@/components/VideoPlayer.vue';
import LessonContentItemVideoStatePlug from '@/components/multicontent/LessonContentItemVideo/LessonContentItemVideoStatePlug.vue';

const defaultOptions: VideoJsPlayerOptions = {
  controls: true,
  sources: [],
  fluid: true,
  fill: true,
  preload: 'none',
};
export default Vue.extend({
  name: 'LessonContentItemVideo',
  components: {
    VideoPlayer,
    LessonContentItemVideoStatePlug,
  },
  props: {
    content: {
      type: Object as PropType<LessonContentItemVideo>,
      required: true,
    },
  },
  data() {
    return {
      playback: 0,
    };
  },
  computed: {
    options() {
      const clonedOptions = cloneDeep(defaultOptions);
      clonedOptions.sources!.push({
        type: 'application/x-mpegURL',
        src: this.content.value,
      });
      return clonedOptions;
    },
    isVideoReady(): boolean {
      return this.content.options.status === LessonContentItemVideoStatus.OK;
    },
  },
  watch: {
    content: {
      immediate: true,
      deep: true,
      handler() {
        if (this.content?.options?.playback) {
          this.playback = this.content?.options?.playback;
        }
      },
    },
  },
});
