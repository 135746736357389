/* eslint-disable max-len, vue/max-len, object-curly-newline */
import transport from '@/services/api/catalogue/transport';
import * as methods from '@/services/api/constants';
import { IAtomWithAssigments } from '@/domains/track';
import { DateTimeISO8601, UUID, IPagination } from '@/domains/common';
import { IAtom, AtomStatus, AtomType } from '@/domains/atom';
import { Category } from '@/domains/category';
import { IContentProvider } from '@/domains/content-provider';
import { Author } from '@/domains/author';

export const atomGet = async (id: UUID): Promise<IAtom> => {
  const response = await transport({
    url: `/v3/catalogue/${id}`,
    method: methods.HTTP_GET,
  });

  return response.atom;
};

export interface ICatalogueAtomsGetParams {
  data?: {},
  params?: {
    page?: number;
    limit?: number;
    ids?: IAtom['id'][];
    atomNames?: AtomType[];
    statuses?: AtomStatus[];
    sort?: 'name_asc' | 'name_desc' | 'updated_at_asc' | 'updated_at_desc';
    search?: string | null;
    duration?: [DateTimeISO8601 | null, DateTimeISO8601 | null],
    providers?: {
      ids: UUID[];
      // eslint-disable-next-line camelcase
      self_provider: boolean;
    };
    authors?: Author['id'][];
    categories?: Category['id'][];
  };
}

export const atomsGet = ({
  data = {},
  params,
}: ICatalogueAtomsGetParams): Promise<{
  atoms: IAtom[],
  pagination: IPagination
}> => transport({
  url: '/v3/catalogue',
  method: methods.HTTP_GET,
  data,
  params,
});

export interface IUserTracksGetResponseData {
  atoms: IAtomWithAssigments[],
  pagination: IPagination,
}

export interface IRecommendedTracksGetResponseData {
  atoms: IAtom[],
  pagination: IPagination,
}

export const userTracksGet = ({
  data = {},
  params,
}: ICatalogueAtomsGetParams): Promise<IUserTracksGetResponseData> => transport({
  url: '/v3/catalogue/my',
  method: methods.HTTP_GET,
  data,
  params,
});

export const recommendedTracksGet = ({
  data = {},
  params,
}: ICatalogueAtomsGetParams): Promise<IRecommendedTracksGetResponseData> => transport({
  url: '/v3/catalogue/my/recommended',
  method: methods.HTTP_GET,
  data,
  params,
});

export interface ICatalogueAtomGetParams {
  id: UUID;
}

export interface ICatalogueContentProvidersGetParams {
  search?: string,
  page?: number,
  limit?: number,
}

export interface ICatalogueContentProvidersGetResponse {
  providers: IContentProvider[];
  pagination: IPagination;
}

export const contentProvidersGet = (params: ICatalogueContentProvidersGetParams = {}): Promise<ICatalogueContentProvidersGetResponse> => transport({
  url: '/v3/providers',
  method: methods.HTTP_GET,
  params,
});

export interface ICatalogueAtomCategoriesGetParams {
  search?: string;
  page?: number;
  limit?: number;
  withTotals?: boolean;
}

export interface ICatalogueAtomCategoriesGetResponse {
  categories: Category[];
  pagination: IPagination;
}

export const atomCategoriesGet = (params?: ICatalogueAtomCategoriesGetParams): Promise<ICatalogueAtomCategoriesGetResponse> => transport({
  url: '/v3/categories',
  method: methods.HTTP_GET,
  params,
});
