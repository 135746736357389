
import Vue, { PropType } from 'vue';
import { VDivider } from 'vuetify/lib';
import { LessonContentItemDivider } from '@/domains/multicontent';

/**
 * Компонент, отображающий отформатированный текст, один из составляющх мультиконтента
 */
export default Vue.extend({
  name: 'LessonContentItemDivider',
  components: {
    VDivider,
  },
  props: {
    content: {
      type: Object as PropType<LessonContentItemDivider>,
      default: () => ({}),
    },
  },
});
