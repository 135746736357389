
import Vue, { PropType } from 'vue';
import { LessonContentItemVideoStatus } from '@/domains/multicontent';

export default Vue.extend({
  name: 'LessonContentItemVideoStatePlug',
  props: {
    status: {
      type: String as PropType<LessonContentItemVideoStatus>,
      default: LessonContentItemVideoStatus.INITIAL,
    },
  },
  computed: {
    description(): string {
      const resolver: Partial<Record<LessonContentItemVideoStatus, string>> = {
        // eslint-disable-next-line max-len,vue/max-len
        [LessonContentItemVideoStatus.ON_TRANSCODING]: this.$t('LessonContentItemVideoStatePlug.description.transcoding'),
      };
      return resolver[this.status] ?? '';
    },
  },
});
