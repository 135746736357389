<template>
  <video
    ref="video"
    class="video-js"
  >
    <slot />
  </video>
</template>

<script>
import videojs from 'video.js';
import throttle from 'lodash/throttle';

export default {
  name: 'VideoPlayer',
  props: {
    options: {
      type: Object,
      required: true,
    },
    volume: {
      type: Number,
      default: 1,
      validator(v) {
        return v >= 0 && v <= 1;
      },
    },
    muted: {
      type: Boolean,
      default: false,
    },
    playback: {
      type: Number,
      default: 0,
      validator(v) {
        return v >= 0;
      },
    },
    playbackRate: {
      type: Number,
      default: 1.0,
    },
    playbackRateItems: {
      type: Array,
      default: () => [0.5, 0.75, 1.0, 1.25, 1.5, 1.75, 2.0],
    },
  },
  data() {
    return {
      player: null,
      volumeInternal: this.volume,
      mutedInternal: this.muted,
      playbackInternal: this.playback,
      playbackRateInternal: this.playbackRate,
      fullscreen: false,
    };
  },
  watch: {
    volume(newValue) {
      this.volumeInternal = newValue;
    },
    volumeInternal(newValue, oldValue) {
      if (newValue !== oldValue) {
        const event = newValue > oldValue
          ? 'volume:up'
          : 'volume:down';
        this.$emit(event, newValue);
        this.$emit('volume:change', newValue);
        this.player.volume(newValue);
      }
    },
    muted(newValue) {
      this.mutedInternal = newValue;
    },
    mutedInternal(newValue) {
      const event = newValue
        ? 'muted:on'
        : 'muted:off';
      this.$emit(event, newValue);
      this.$emit('muted:change', newValue);
      this.player.muted(newValue);
    },
    playback(newValue) {
      this.playbackInternal = newValue;
      this.player.currentTime(newValue);
    },
    playbackInternal(newValue, oldValue) {
      if (newValue !== oldValue) {
        const event = newValue > oldValue
          ? 'playback:up'
          : 'playback:down';
        // eslint-disable-next-line no-underscore-dangle
        this.$emit(event, newValue);
        this.$emit('progress', {
          progress: Math.trunc((newValue / this.player.duration()) * 100),
          duration: this.player.duration(),
        });
        this.$emit('playback:change', newValue);
      }
    },
    playbackRate(newValue) {
      this.playbackRateInternal = newValue;
    },
    playbackRateInternal(newValue, oldValue) {
      if (newValue !== oldValue) {
        const event = newValue > oldValue
          ? 'playback-rate:up'
          : 'playback-rate:down';
        this.$emit(event, newValue);
        this.$emit('playback-rate:change', newValue);
        this.player.playbackRate(newValue);
      }
    },
    fullscreen(newValue, oldValue) {
      if (newValue !== oldValue) {
        const event = newValue
          ? 'fullscreen:on'
          : 'fullscreen:off';
        this.$emit(event, newValue);
        this.$emit('fullscreen:change', newValue);
      }
    },
  },
  mounted() {
    // TODO: вынести в отдельный модуль
    // TODO: Разнести options на пропсы до конца
    const options = { ...this.options, playbackRates: this.playbackRateItems };
    this.player = videojs(this.$refs.video, options, () => {
      this.player.volume(this.volume);
      this.player.muted(this.muted);
      this.player.currentTime(this.playback);
      this.player.playbackRate(this.playbackRate);
    });

    // Note: кастомизируем контролы плеера
    this.player.bigPlayButton.addClass('vjs-show-big-play-button-on-pause');

    // Note: добавляем аттрибуты для теста
    this.player.bigPlayButton.setAttribute('data-test', 'button-play-big');
    this.player.controlBar.playToggle.setAttribute('data-test', 'button-play-small');

    // Note: навешиваем события
    const throttledCompleteLesson = throttle(this.completeLesson, 1000);
    this.player.on('timeupdate', throttledCompleteLesson);

    this.initEvents(this.player);
  },
  beforeDestroy() {
    if (this.player) {
      this.destroyPlayer();
    }
  },
  methods: {
    updatePlayback() {
      this.playbackInternal = this.player.currentTime();
    },
    updateVolume() {
      this.volumeInternal = this.player.volume();
    },
    destroyPlayer() {
      this.player.dispose();
      this.player = null;
    },
    durationLessThenThreshold() {
      const MIN_DURATION_THRESHOLD = 180; // Note: в секундах
      return this.player.duration() < MIN_DURATION_THRESHOLD;
    },
    videoCompleteLessThreshold() {
      const REQUIRED_PERCENT_TO_COMPLETE = 95;
      const duration = Math.floor(this.player.duration());
      const currentTime = Math.floor(this.player.currentTime());
      const isCompleted = (duration / 100) * REQUIRED_PERCENT_TO_COMPLETE < currentTime;

      if (isCompleted) this.$emit('completed:lesson');
    },
    videoCompleteMoreThreshold() {
      const REQUIRED_SECOND_TO_COMPLETE = 30;
      const duration = Math.floor(this.player.duration());
      const currentTime = Math.floor(this.player.currentTime());
      const isCompleted = (duration - currentTime) < REQUIRED_SECOND_TO_COMPLETE;

      if (isCompleted) this.$emit('completed:lesson');
    },
    completeLesson() {
      if (this.durationLessThenThreshold()) {
        this.videoCompleteLessThreshold();
      } else {
        this.videoCompleteMoreThreshold();
      }
    },
    initEvents(player = this.player) {
      // NOTE: Вызывать player.off() не требуется, вызовется сам в player.dispose()

      // play, pause
      player.on('play', () => this.$emit('play', this.player.currentTime()));
      player.on('pause', () => this.$emit('pause', this.player.currentTime()));

      // playback:up, playback:down, playback:change
      player.on('timeupdate', throttle(this.updatePlayback, 1000)); // TODO: Вынести таймаут в пропс

      // volume:up, volume:down, volume:change
      player.on('volumechange', throttle(this.updateVolume, 1000));

      // muted:on, muted:off, muted:change
      const observer = new MutationObserver((mutations) => {
        if (mutations.length) {
          this.mutedInternal = this.player.muted();
        }
      });
      observer.observe(this.$refs.video, {
        attributes: true,
        attributeFilter: ['muted'],
      });

      // fullscreen:on, fullscreen:off, fullscreen:change
      player.on('fullscreenchange', () => {
        this.fullscreen = this.player.isFullscreen();
      });

      // seeking:up, seeking:down, seeking:change
      player.on('seeking', () => {
        const timePrev = this.playbackInternal;
        const timeNext = this.player.currentTime();
        if (timePrev !== timeNext) {
          const event = timePrev > timeNext
            ? 'seeking:up'
            : 'seeking:down';
          this.$emit(event, timeNext);
          this.$emit('seeking:change', timeNext);
        }
      });

      // playback-rate:up, playback-rate:down, playback-rate:change
      player.on('ratechange', () => {
        this.playbackRateInternal = this.player.playbackRate();
      });
    },
  },
};
</script>

<style lang="scss">
@import '~video.js/dist/video-js.min.css';

/* Не трогать! Внутри очень странные хаки! */
/* Подробнее: https://www.simonbattersby.com/blog/browsers-and-fractional-pixels/*/
.video-js {
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  background: none !important;

  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    margin: 0 auto;
    top: 0;
    background: map-get($tt-light-mono-100, 'base');
    border-radius: 12px;
    border: 0.1px solid white;
  }

  .vjs-tech {
    width: calc(100% + 0.76px);
    height: calc(100% + 0.76px);
    background: none !important;
  }
  .vjs-control-bar {
    width: calc(100% - 0.76px);
  }

  &.vjs-paused {
    .vjs-show-big-play-button-on-pause {
      display: block !important;
    }
  }

  .vjs-big-play-button {
    $button-size-width: 100px;
    $button-size-height: 50px;
    width: $button-size-width;
    height: $button-size-height;
    left: calc(50% - calc(#{$button-size-width} / 2));
    top: calc(50% - calc(#{$button-size-height} / 2));
  }

  .vjs-menu-button-popup {
    .vjs-menu {
      .vjs-menu-content {
        max-height: unset;
      }
    }
  }
}
</style>
