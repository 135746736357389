
import Vue, { PropType } from 'vue';

import { LessonContentItemYoutube } from '@/domains/multicontent';

export default Vue.extend({
  name: 'LessonContentItemYoutube',
  props: {
    content: {
      type: Object as PropType<LessonContentItemYoutube>,
      required: true,
    },
  },
  computed: {
    startSeconds(): number {
      return this.content.options.startSeconds ?? 0;
    },
    src(): string {
      return `https://www.youtube.com/embed/${this.content.value}?start=${this.startSeconds}`;
    },
    allow(): string {
      return this.content.options.allow
        ? this.content.options.allow.join(';')
        : 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture';
    },
    allowfullscreen(): boolean {
      return this.content.options.allowfullscreen ?? true;
    },
    frameborder(): number {
      return this.content.options.frameborder ?? 0;
    },
    title(): string {
      return this.content.options.title ?? 'YouTube video player';
    },
  },
});
