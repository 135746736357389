export type PlayerSessionId = number;

export enum PlayerSessionStatus {
    NEW = 'new',
    ACTIVE = 'active',
    COMPLETED = 'completed',
    CANCELED = 'canceled',
    DELETED = 'deleted',
    UNKNOWN = 'unknown' // Используется на странице до того как пришли даннын из ручки
}
