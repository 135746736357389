
import {
  defineComponent, ref, watch, PropType,
} from 'vue';

import { extract, OembedData } from 'oembed-parser';

type OEmbedData = OembedData & { html?: string };

export default defineComponent({
  name: 'OEmbed',
  props: {
    src: {
      type: String as PropType<string>,
      default: '',
    },
  },
  setup(props, { emit }) {
    const content = ref<string>('');

    const error = ref<boolean>(false);

    async function fetch(): Promise<void> {
      try {
        const data: OEmbedData = await extract(props.src) as OEmbedData;
        content.value = data?.html ?? '';
        error.value = false;
        emit('success');
      } catch {
        content.value = '';
        error.value = true;
        emit('error');
      }
    }

    watch(() => props.src, fetch, { immediate: true });

    return {
      content,
      error,
      fetch,
    };
  },
});
