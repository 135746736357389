
import { defineComponent, PropType } from 'vue';
import { LessonContentItemFile, LessonContentItemType } from '@/domains/multicontent';

import FileIcon from '@/components/ui/FileIcon/FileIcon.vue';
import { fileSize } from '@/helpers/formats/fileSize/fileSize';
import { FeatureFlag } from '@/plugins/feature-flags/components';
import * as MultiContentService from '@/services/api/multi-content';
import { downloadFileByUrl } from '@/helpers/url';
import { FileIconType } from '@/domains/multicontent/media';

export default defineComponent({
  name: 'LessonContentItemFile',

  components: {
    FileIcon,
    FeatureFlag,
  },

  props: {
    content: {
      type: Object as PropType<LessonContentItemFile>,
      default: () => ({
        type: LessonContentItemType.FILES,
        value: '',
        options: {},
      }),
    },
  },

  computed: {
    playerSessionId(): number {
      return Number(this.$route.params.playerSessionId);
    },

    fileIcon(): FileIconType {
      if (this.content?.options.filename) {
        return (this.content?.options.filename.split('.').pop() as FileIconType) || FileIconType.DEFAULT;
      }

      return FileIconType.DEFAULT;
    },
  },

  methods: {
    fileSize(): string {
      return fileSize(this.content.options.size);
    },

    async downloadFile() {
      try {
        const { downloadUrl } = await MultiContentService
          .fileGet({ playerSessionId: this.playerSessionId, mediaId: this.content?.options.id });

        const resultFile = await MultiContentService.uploadFile(downloadUrl);
        await downloadFileByUrl({
          resultFile,
          document,
          file: {
            name: this.content?.options?.filename,
            mime: this.content?.options?.mime,
          },
        });
      } catch (e) {
        this.$di.notify.error({ content: this.$t('LessonContentItemFile.error.file') });
      }
    },
  },
});
