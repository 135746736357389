import { UUID } from '@/domains/common';

export enum MimeType {
  PDF = 'application/pdf',
  POWERPOINT = 'application/vnd.ms-powerpoint',
  EXCEL = 'application/vnd.ms-excel',
  WORD = 'application/msword',
  MPEG = 'audio/mpeg',
  WAV = 'audio/wav',
  RAR = 'application/x-rar',
  ZIP = 'application/zip',
  POWERPOINT_OFFICE = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  EXCEL_MACRO_ENABLED = 'application/vnd.ms-excel.sheet.macroEnabled.12',
  SPREADSHEET = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  WORD_PROCESSING = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  TEXT = 'text/csv',
  RTF = 'application/rtf',
  TEXT_PLAIN = 'text/plain',
  XML = 'application/xml',
  XML_TEXT = 'text/xml',
  BMP = 'image/bmp',
}

export enum FileIconType {
  DEFAULT = 'default',
  PDF = 'pdf',
  POWERPOINT = 'ppt',
  POWERPOINT_OFFICE = 'pptx',
  EXCEL = 'xls',
  EXCEL_MACRO_ENABLED = 'xlsx',
  WORD = 'doc',
  WORD_PROCESSING = 'docx',
  TEXT = 'txt',
  XML_TEXT = 'xml',
  BMP = 'bmp',
  MPEG = 'mp3',
  WAV = 'wav',
  RAR = 'rar',
  ZIP = 'zip',
  RTF = 'rtf',
  CSV = 'csv',
}

export enum FileStatus {
  UPLOADED = 'uploaded',
  INVALID = 'invalid',
}

export interface IUploadedMedia {
  id: UUID, // ID Media
  status: FileStatus // Статус Media ("uploaded", "invalid")
  ext: string, // Расширение файла
  mime: MimeType, // mime-type файла
  size: number, // Размер файла в байтах
  filename: string // Имя файла
}
