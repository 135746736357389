
import Vue, { PropType } from 'vue';
import { LessonContentItemAudio } from '@/domains/multicontent';

export default Vue.extend({
  name: 'LessonContentItemAudio',
  props: {
    content: {
      type: Object as PropType<LessonContentItemAudio>,
      required: true,
    },
  },
});
