import {
  ITrackStepRunResponseData,
  ITrackStepRunRequest,
  ITrackGetResponseData,
  ITrackCurrentStepGetResponseData,
  ITrackSessionGetResponseData,
  ITrackCheckNewVersionResponseData,
} from '@/services/api/tracks/types';

import transport from '@/services/api/tracks/transport';
import * as methods from '@/services/api/constants';
import { UUID } from '@/domains/common';

/**
 * Запуск сессии (с иерархией разделов)
 * @param {ITrackStepRunRequest} request
 * @return {ITrackStepRunResponseData}
 */
export const trackStepRun = async (request: ITrackStepRunRequest):
  Promise<ITrackStepRunResponseData> => transport({
  url: `/v4/tracks/player/${request.trackSessionId}/steps/${request.stepId}/run`,
  method: methods.HTTP_POST,
  data: {
    forceSync: request.forceSync,
    forceNewVersion: request.forceNewVersion,
  },
});

/**
 * Получить информацию о шаге по trackSession/id
 * @param {number} trackSessionId
 * @return {ITrackCurrentStepGetResponseData}
 */
export const trackCurrentStepGet = async (
  { trackSessionId }:
    { trackSessionId: number },
): Promise<ITrackCurrentStepGetResponseData> => transport({
  url: `/v4/tracks/player/${trackSessionId}/current_step`,
  method: methods.HTTP_GET,
});

/**
 * Получить данные о разделе
 * @param {UUID} id
 * @return {ITrackGetResponseData}
 */
export const trackGet = async (
  { id }: { id: UUID },
): Promise<ITrackGetResponseData> => transport({
  url: `/v4/tracks/${id}`,
  method: methods.HTTP_GET,
});

/**
 * Получить данные о сессии
 * @param {UUID} trackSessionId
 * @return {ITrackGetResponseData}
 */
interface trackSessionGetProps {
  trackSessionId: number;
}
export const trackSessionGet = async (
  { trackSessionId }: trackSessionGetProps,
): Promise<ITrackSessionGetResponseData> => transport({
  url: `/v4/tracks/player/${trackSessionId}`,
  method: methods.HTTP_GET,
});

/**
 * Получить информацию о шаге по trackSession/id
 * @param {number} trackSessionId
 * @return {ITrackCheckNewVersionResponseData}
 */
export const trackCheckNewVersion = async (
  trackSessionId: number,
): Promise<ITrackCheckNewVersionResponseData> => transport({
  url: `/v4/tracks/player/${trackSessionId}/check_version`,
  method: methods.HTTP_GET,
});
