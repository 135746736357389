
import { computed, defineComponent, PropType } from 'vue';
import { LessonContentItemRichText } from '@/domains/multicontent';
import RichTextViewer from '@/components/ui/RichText/ui/RichTextViewer.vue';
import { useFeatureFlag } from '@/plugins/feature-flags';

/**
 * Компонент, отображающий отформатированный текст, один из составляющх мультиконтента
 */
export default defineComponent({
  name: 'LessonContentItemRichText',
  components: { RichTextViewer },
  props: {
    content: {
      type: Object as PropType<LessonContentItemRichText>,
      required: true,
    },
  },

  setup(props) {
    const isRichTextAvailable = useFeatureFlag('multi_content_richtext');

    return {
      isRichTextAvailable,
      value: computed(() => props.content.value),
    };
  },
});
