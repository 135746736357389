
import { defineComponent, PropType } from 'vue';
import { FileIconType } from '@/domains/multicontent/media';

export default defineComponent({
  name: 'FileIcon',
  props: {
    type: {
      type: String as PropType<FileIconType>,
      default: FileIconType.DEFAULT,
    },
  },

  data() {
    return {
      iconsList: {
        default: '$file',
        link: '$link',
        pdf: '$filePdfLight',
        ppt: '$filePowerPointLight',
        pptx: '$filePowerPointLight',
        xls: '$fileExcelLight',
        xlsx: '$fileExcelLight',
        doc: '$fileWordLight',
        docx: '$fileWordLight',
        mp3: '$fileMusicLight',
        wav: '$fileMusicLight',
        rar: '$fileArchiveLight',
        zip: '$fileArchiveLight',
        txt: '$file',
        xml: '$file',
        bmp: '$file',
        csv: '$file',
        rtf: '$file',
      },
    };
  },

  computed: {
    icon(): string {
      return this.iconsList[this.type];
    },
  },
});
