
import {
  defineComponent, PropType, ref,
} from 'vue';
import { LessonContentItemImage } from '@/domains/multicontent';

export default defineComponent({
  name: 'LessonContentItemImage',
  props: {
    content: {
      type: Object as PropType<LessonContentItemImage>,
      required: true,
    },
  },
  setup() {
    const isImageVisible = ref<boolean>(false);
    return {
      isImageVisible,
    };
  },
});
