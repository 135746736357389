
import Vue, { PropType } from 'vue';
import LessonContentItem from '@/components/multicontent/LessonContentItem/LessonContentItem.vue';
import { LessonContentItemBase, LessonContentItemType } from '@/domains/multicontent';
/**
 * Компонент, отображающий блоки контента
 */
export default Vue.extend({
  name: 'LessonContent',
  components: {
    LessonContentItem,
  },
  props: {
    contentItems: {
      type: Array as PropType<LessonContentItemBase[]>,
      default: () => [],
    },
  },
  methods: {
    /**
     * Возвращаются разные отступы для разных типов блоков с контентом
     */
    getMarginsByType(type: LessonContentItemType): string {
      // TODO: Перенести отступы в соответствующие компоненты
      if (type === LessonContentItemType.TEXT || type === LessonContentItemType.RICH_TEXT) {
        return 'mb-6';
      }
      if (type === LessonContentItemType.DIVIDER) {
        return 'mt-4 mb-4';
      }
      if (type === LessonContentItemType.IMAGE) {
        return 'mt-3 mb-9';
      }

      return 'mt-9 mb-6';
    },
  },
});
