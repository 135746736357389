/**
 * Вычисляет и обрезает до второго знака размер файла
 * @param {number} size - размер файла в байтах
 */
/* eslint-disable import/prefer-default-export */
export function fileSize(size: number = 0): string {
  if (!size) {
    return '0 Б';
  }

  const units = ['КБ', 'MБ', 'ГБ'];
  let counter = 0;
  let tempSize = size;
  let resultSize = '';

  if (tempSize / 1024 < 1) {
    resultSize = `${Math.trunc(tempSize * 100) / 100} Б`;
  }

  while (tempSize / 1024 > 1 && !resultSize && counter < units.length) {
    tempSize /= 1024;

    if (tempSize / 1024 < 1) {
      resultSize = `${Math.trunc(tempSize * 100) / 100} ${units[counter]}`;
    }
    counter += 1;
  }

  if (!resultSize) {
    resultSize = `${Math.trunc(tempSize * 100) / 100} ${units[units.length - 1]}`;
  }

  return resultSize;
}
