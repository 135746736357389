
import {
  defineComponent, toRef, computed, PropType,
} from 'vue';
import { useRouter, useRoute } from 'vue-router/composables';
import { IAtom, AtomType } from '@/domains/atom';
import { useFeatureFlag } from '@/plugins/feature-flags';
import { useI18n } from '@/plugins/vue-i18n';
import { trackStepRun } from '@/services/api/tracks';
import { userAssignmentRun } from '@/services/api/lxp-assignment';

import { Names } from '@/plugins/vue-router';
import { IAssignment } from '@/domains/assignment';

export default defineComponent({
  name: 'AtomHasNewVersionBanner',

  props: {
    atomType: {
      type: String as PropType<AtomType>,
      default: '',
    },
    assignmentUserId: {
      // можно взять из $route.params.assignmentId:"49177",
      // но его нет, если зайти в шаг и перейти в курс через breadcrumbs
      type: Number as PropType<IAssignment['assignmentUserId']>,
      default: NaN,
    },
    catalogueAtomMainId: {
      type: String as PropType<IAtom['id']>,
      default: '',
    },
  },

  setup(props, { emit }) {
    const atomType = toRef(props, 'atomType');

    const flags: Record<AtomType, boolean> = {
      [AtomType.MULTICONTENT]: useFeatureFlag('edit_publish_multi_content').value,
      [AtomType.MULTILEVELTEST]: useFeatureFlag('edit_publish_multilevel_test').value,
      [AtomType.QUIZ]: useFeatureFlag('edit_publish_quiz').value,
      [AtomType.SCORM]: useFeatureFlag('edit_publish_scorm').value,
      [AtomType.TRACK]: true,
      [AtomType.PROGRAM]: useFeatureFlag('new_atom_program').value,
      [AtomType.UNKNOWN]: false,
    };

    const isAvailable = computed<boolean>(() => flags[atomType.value] ?? false);

    const { t } = useI18n();

    const title = computed<string>(() => {
      const resolver: Record<AtomType, string> = {
        [AtomType.MULTICONTENT]: t('AtomHasNewVersionBanner.title'),
        [AtomType.SCORM]: t('AtomHasNewVersionBanner.title'),
        [AtomType.MULTILEVELTEST]: t('AtomHasNewVersionBanner.test.title'),
        [AtomType.QUIZ]: t('AtomHasNewVersionBanner.test.title'),
        [AtomType.TRACK]: t('AtomHasNewVersionBanner.track.title'),
        [AtomType.PROGRAM]: '',
        [AtomType.UNKNOWN]: '',
      };

      return resolver[atomType.value] ?? '';
    });

    const description = computed<string>(() => {
      const resolver: Record<AtomType, string> = {
        [AtomType.MULTICONTENT]: t('AtomHasNewVersionBanner.description'),
        [AtomType.SCORM]: t('AtomHasNewVersionBanner.description'),
        [AtomType.MULTILEVELTEST]: t('AtomHasNewVersionBanner.test.description'),
        [AtomType.QUIZ]: t('AtomHasNewVersionBanner.test.description'),
        [AtomType.TRACK]: t('AtomHasNewVersionBanner.track.description'),
        [AtomType.PROGRAM]: '',
        [AtomType.UNKNOWN]: '',
      };

      return resolver[atomType.value] ?? '';
    });

    const router = useRouter();
    const route = useRoute();

    async function goToNewVersion() {
      function pageByType(type: AtomType): Names | '' {
        const pages: Partial<Record<AtomType, Names>> = {
          [AtomType.QUIZ]: Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_QUIZ_VIEW,
          [AtomType.MULTICONTENT]: Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_MULTICONTENT,
          [AtomType.SCORM]: Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_SCORM_VIEW,
          [AtomType.TRACK]: Names.R_APP_LEARNING_SESSION_PLAYER_ATOM_PREVIEW,
        };
        return pages[type] ?? '';
      }

      try {
        if (atomType.value === AtomType.TRACK) {
          const { playerSessionId } = await userAssignmentRun({
            assignmentUserId: props.assignmentUserId,
            data: {
              catalogueAtomMainId: props.catalogueAtomMainId,
              forceNewVersion: true,
            },
          });

          await router.replace({
            name: pageByType(atomType.value),
            params: {
              assignmentId: route.params.assignmentId,
              trackSessionId: `${playerSessionId}`,
              atomType: atomType.value,
            },
          });
        } else {
          const response = await trackStepRun({
            trackSessionId: Number(route.params.trackSessionId),
            stepId: route.params.stepId,
            forceNewVersion: true,
          });

          emit('new-version:loaded');

          await router.replace({
            name: pageByType(atomType.value),
            params: {
              assignmentId: route.params.assignmentId,
              trackSessionId: route.params.trackSessionId,
              stepId: route.params.stepId,
              playerSessionId: response.playerSessionId.toString(),
            },
          });
        }
      } catch (e) {
        console.error(e);
      }
    }

    return {
      isAvailable,
      title,
      description,
      goToNewVersion,
    };
  },
});
