
import Vue, { PropType } from 'vue';

import LessonContentItemImage from '@/components/multicontent/LessonContentItemImage/LessonContentItemImage.vue';
import LessonContentItemAudio from '@/components/multicontent/LessonContentItemAudio/LessonContentItemAudio.vue';
import LessonContentItemVideo from '@/components/multicontent/LessonContentItemVideo/LessonContentItemVideo.vue';
import LessonContentItemText from '@/components/multicontent/LessonContentItemText/LessonContentItemText.vue';
import LessonContentItemRichText from '@/components/multicontent/LessonContentItemRichText/LessonContentItemRichText.vue';
import LessonContentItemDivider from '@/components/multicontent/LessonContentItemDivider/LessonContentItemDivider.vue';
import LessonContentItemYoutube from '@/components/multicontent/LessonContentItemYoutube/LessonContentItemYoutube.vue';
import LessonContentItemFile from '@/components/multicontent/LessonContentItemFile/LessonContentItemFile.vue';
import LessonContentItemLink from '@/components/multicontent/LessonContentItemLink/LessonContentItemLink.vue';

import { LessonContentItemBase, LessonContentItemType } from '@/domains/multicontent';

export default Vue.extend({
  name: 'LessonContentItem',
  components: {
    LessonContentItemImage,
    LessonContentItemAudio,
    LessonContentItemVideo,
    LessonContentItemText,
    LessonContentItemRichText,
    LessonContentItemDivider,
    LessonContentItemYoutube,
    LessonContentItemFile,
    LessonContentItemLink,
  },
  props: {
    content: {
      type: Object as PropType<LessonContentItemBase>,
      required: true,
    },
  },
  computed: {
    dataTest(): string {
      if (this.content.type === LessonContentItemType.TEXT) {
        return 'text-content';
      }
      if (this.content.type === LessonContentItemType.IMAGE) {
        return 'image-content';
      }
      if (this.content.type === LessonContentItemType.VIDEO) {
        return 'video-content';
      }
      if (this.content.type === LessonContentItemType.YOUTUBE) {
        return 'youtube-video-content';
      }
      if (this.content.type === LessonContentItemType.LINK) {
        return 'link-content';
      }
      return '';
    },
  },
  methods: {
    getComponent(contentType: LessonContentItemType) {
      const resolver = {
        [LessonContentItemType.TEXT]: LessonContentItemText,
        [LessonContentItemType.RICH_TEXT]: LessonContentItemRichText,
        [LessonContentItemType.IMAGE]: LessonContentItemImage,
        [LessonContentItemType.AUDIO]: LessonContentItemAudio,
        [LessonContentItemType.VIDEO]: LessonContentItemVideo,
        [LessonContentItemType.FILES]: LessonContentItemFile,
        [LessonContentItemType.DIVIDER]: LessonContentItemDivider,
        [LessonContentItemType.YOUTUBE]: LessonContentItemYoutube,
        [LessonContentItemType.LINK]: LessonContentItemLink,
      };
      return resolver[contentType];
    },
  },
});
